export const jiraColumns = [
    {
      name: "Title",
      key: "title",
      sortable: false,
      clickable: false,
      visible: true,
    },
    {
      name: "Clarified Title",
      key: "clarifiedTitle",
      sortable: false,
      clickable: true,
      visible: true,
    },
    {
      name: "Ticket ID",
      key: "number",
      sortable: false,
      clickable: true,
      visible: true,
    },
    {
      name: "Date Created",
      key: "createdDate",
      sortable: true,
      clickable: false,
      visible: true,
    },
    {
      name: "AI Generated Severity",
      key: "severity",
      sortable: true,
      clickable: false,
      visible: true,
    },
    {
      name: "Status",
      key: "status",
      sortable: false,
      clickable: false,
      visible: true,
    },
    {
      name: "State",
      key: "state",
      sortable: false,
      clickable: false,
    },
    {
      name: "Email",
      key: "email",
      sortable: false,
      clickable: false,
    },
    {
      name: "Phone",
      key: "mobile",
      sortable: false,
      clickable: false,
    },
    {
      name: "Module",
      key: "modules",
      sortable: false,
      clickable: false,
    },
    {
      name: "AI Assistant",
      key: "aiAssistant",
      sortable: false,
      clickable: true,
      visible: true,
    },
  ];
  