<template>
  <div>
    <div class="controls">
      <v-select
        label="Time Granularity"
        :items="['Date', 'Week', 'Month']"
        v-model="timeGranularity"
        @change="filterData"
        outlined
      ></v-select>
    </div>
    <Plotly
      v-if="
        processedData.categories.length > 0 &&
        processedData.seriesData.length > 0
      "
      :data="plotlyData"
      :layout="plotlyLayout"
      :display-mode-bar="false"
      ref="plotlyChart"
    ></Plotly>
  </div>
</template>

<script>
import moment from "moment";
import { Plotly } from "vue-plotly";

export default {
  name: "BarChart",
  components: {
    Plotly,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      timeGranularity: "Month",
    };
  },
  computed: {
    uniqueMonths() {
      const months = new Set();
      this.data.forEach((ticket) => {
        const monthYear = moment(
          ticket.createdDate,
          "DD-MM-YYYY HH:mm:ss"
        ).format("MMMM YYYY");
        months.add(monthYear);
      });
      return Array.from(months);
    },
    processedData() {
      const dataMap = {};
      const allKeys = this.getAllPossibleKeys();
      allKeys.forEach((key) => {
        dataMap[key] = 0;
      });

      this.data.forEach((ticket) => {
        let timeKey;
        if (this.timeGranularity === "Week") {
          const startOfWeek = moment(
            ticket.createdDate,
            "DD-MM-YYYY HH:mm:ss"
          ).startOf("isoWeek");
          timeKey = startOfWeek.format("DD MMM YY");
        } else if (this.timeGranularity === "Month") {
          timeKey = moment(ticket.createdDate, "DD-MM-YYYY HH:mm:ss").format(
            "MMMM YYYY"
          );
        } else {
          timeKey = moment(ticket.createdDate, "DD-MM-YYYY HH:mm:ss").format(
            "YYYY-MM-DD"
          );
        }
        dataMap[timeKey]++;
      });

      const sortedKeys = this.sortKeys(allKeys);

      return {
        categories: sortedKeys,
        seriesData: sortedKeys.map((key) => dataMap[key]),
      };
    },
    plotlyData() {
      return [
        {
          x: this.processedData.categories,
          y: this.processedData.seriesData,
          type: "bar",
          marker: {
            color: "#2196F3",
          },
        },
      ];
    },
    plotlyLayout() {
      let xAxisTitle;
      let xAxisType;

      switch (this.timeGranularity) {
        case "Week":
          xAxisTitle = "Week";
          xAxisType = "category";
          break;
        case "Month":
          xAxisTitle = "Month";
          xAxisType = "category";
          break;
        case "Date":
        default:
          xAxisTitle = "Date";
          xAxisType = "date";
      }
      const yAxisGranularity = this.processedData.categories.length > 10 ? 2 : 1;
      return {
        title: "Age of Open Linked Jira Tickets",
        xaxis: {
          title: xAxisTitle,
          type: xAxisType,
        },
        yaxis: {
          title: "Number of Open L2 Tickets Linked",
          tickmode: "linear",
          tick0: 0,
          dtick: yAxisGranularity,
        },
      };
    },
  },
  methods: {
    filterData() {
      this.$refs.plotlyChart.update();
    },
    getAllPossibleKeys() {
      const allKeys = new Set();
      const dateFormat =
        this.timeGranularity === "Date"
          ? "YYYY-MM-DD"
          : this.timeGranularity === "Week"
          ? "DD MMM YY"
          : "MMMM YYYY";

      // Determine the start and end dates
      const allDates = this.data.map((ticket) =>
        moment(ticket.createdDate, "DD-MM-YYYY HH:mm:ss")
      );
      const startDate = moment.min(allDates);
      const endDate = moment.max(allDates);

      if (this.timeGranularity === "Month") {
        const current = startDate.clone().startOf("month");
        while (current.isBefore(endDate) || current.isSame(endDate, "month")) {
          allKeys.add(current.format(dateFormat));
          current.add(1, "month");
        }
      } else if (this.timeGranularity === "Week") {
        const current = startDate.clone().startOf("isoWeek");
        while (current.isBefore(endDate) || current.isSame(endDate, "week")) {
          allKeys.add(current.format(dateFormat));
          current.add(1, "week");
        }
      } else {
        const current = startDate.clone();
        while (current.isBefore(endDate) || current.isSame(endDate, "day")) {
          allKeys.add(current.format(dateFormat));
          current.add(1, "day");
        }
      }

      return Array.from(allKeys);
    },
    sortKeys(keys) {
      if (this.timeGranularity === "Month") {
        const monthOrder = {
          January: 1,
          February: 2,
          March: 3,
          April: 4,
          May: 5,
          June: 6,
          July: 7,
          August: 8,
          September: 9,
          October: 10,
          November: 11,
          December: 12,
        };
        return keys.sort((a, b) => {
          const [monthA, yearA] = a.split(" ");
          const [monthB, yearB] = b.split(" ");
          if (yearA !== yearB) {
            return yearA - yearB;
          }
          return monthOrder[monthA] - monthOrder[monthB];
        });
      } else {
        return keys.sort(
          (a, b) =>
            moment(a, "DD MMM YY").toDate() - moment(b, "DD MMM YY").toDate()
        );
      }
    },
  },
  async mounted() {
    await this.$nextTick();
    this.$refs.plotlyChart.update();
  },
  watch: {
    data: {
      immediate: true,
      handler() {
        this.$refs.plotlyChart.update();
      },
    },
  },
};
</script>

<style scoped>
.controls {
  padding: 1rem;
  width: 200px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 60px;
  right: 80px;
  z-index: 1;
}
</style>
