<template>
  <div class="table_container">
    <div>
      <v-select
        v-model="selectedHeaders"
        :items="totalHeaders"
        label="Select Columns"
        multiple
        outlined
        return-object
        class="v-select-header"
      >
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index < 2">
            <span>{{ item }}</span>
          </v-chip>
          <span v-if="index === 2" class="grey--text caption"
            >(+{{ selectedHeaders.length - 2 }} others)</span
          >
        </template>
      </v-select>
    </div>
    <v-data-table
      :items="data"
      :headers="createHeader"
      :search="search"
      :items-per-page="pageSize"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 100, -1],
      }"
      :calculate-widths="true"
      :custom-sort="customSort"
      :hide-default-footer="hideFooter"
      :sort-by.sync="localSortBy"
      :sort-desc.sync="localSortDesc"
      class="elevation-1 ew-custom-table"
    >
      <template v-slot:item="{ item }">
        <tr class="custom-table-tr">
          <td
            v-for="header in createHeader"
            v-bind:key="header.Id"
            :class="`${header.value}-column`"
            :style="getCellStyle(header.value, item)"
            v-on:click="GoToPatient(header.value, header.clickable, item)"
          >
            <div
              v-if="typeof item[header.value] === 'string'"
              class="table-generic-cursor"
              :class="getClassForHeaderName(header.text)"
              :title="item[header.value]"
            >
              {{ formatDate(item[header.value], header.value) }}
            </div>
            <div
              v-else
              v-html="item[header.value]"
              class="table-generic-cursor"
              :class="getClassForHeaderName(header.text)"
            />
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import EventBus from "../../../utils/EventBus";
import moment from "moment";
export default {
  data() {
    return {
      localSortBy: this.sortBy,
      localSortDesc: this.sortDesc,
      selectedHeaders: [],
      totalHeaders: [],
    };
  },
  computed: {
    createHeader() {
      return this.columns
        .filter((column) => this.selectedHeaders.includes(column.name))
        .map((column, index) => ({
          text: column.name,
          value: column.key,
          sortable: column.sortable,
          clickable: column.clickable,
          Id: index + 1,
        }));
    },
  },  
  props: {
    pageSize: {
      type: Number,
      default: 20,
    },
    columns: Array,
    data: Array,
    title: {
      default: "",
      type: String,
    },
    search: {
      default: "",
      type: String,
    },
    hideFooter: {
      default: true,
      type: Boolean,
    },
    colMaxWidth: {
      type: Number,
      default: 0,
    },
    addDeleteIcon: {
      type: Boolean,
      default: false,
    },
    sortBy: {
      type: String,
      default: "",
    },
    sortDesc: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    sortBy(newVal) {
      this.localSortBy = newVal;
    },
    sortDesc(newVal) {
      this.localSortDesc = newVal;
    },
  },
  methods: {
    getClassForHeaderName(value) {
      const headerToClass = new Map([
        ["Ticket ID", "hyperlink"],
        ["AI Assistant", "hyperlink"],
        ["Number of L2 Tickets Linked", "hyperlink"],
        ["User Id", "hyperlink"],
      ]);
      return headerToClass.get(value) || "";
    },
    GoToPatient(column, clickable, item) {
      if (clickable) {
        EventBus.$emit("clickTableEvent", column, item);
        this.$emit("clickTableEvent", column, item);
      }
    },
    DeleteEvent(item) {
      this.$emit("deleteRowEvent", item);
    },
    customSort: function (items, index, isDesc) {
      if (index.length !== 0) {
        items.sort((a, b) => {
          if (index[0].includes("Date")) {
            const dateA = a[index[0]]
              ? moment(a[index[0]], "DD-MM-YYYY HH:mm:ss").valueOf()
              : null;
            const dateB = b[index[0]]
              ? moment(b[index[0]], "DD-MM-YYYY HH:mm:ss").valueOf()
              : null;

            if (dateA === dateB) {
              return 0;
            } else if (dateA === null) {
              return 1;
            } else if (dateB === null) {
              return -1;
            } else if (!isDesc[0]) {
              return dateB - dateA;
            } else {
              return dateA - dateB;
            }
          } else {
            if (a[index[0]] === b[index[0]]) {
              return 0;
            } else if (a[index[0]] === null) {
              return 1;
            } else if (b[index[0]] === null) {
              return -1;
            } else if (!isDesc[0]) {
              return a[index[0]] < b[index[0]] ? -1 : 1;
            } else {
              return a[index[0]] < b[index[0]] ? 1 : -1;
            }
          }
        });
      }
      return items;
    },
    formatDate(value, column) {
      if (column.includes("Date") && value) {
        const val = moment(value, "DD-MM-YYYY").format('DD-MM-YY')
        return val
      }
      return value;
    },
    getCellStyle(column, item) {
      const severityColors = {
        1: "rgba(255, 0, 0, 0.6)",
        2: "rgba(255, 0, 0, 0.4)",
        3: "rgba(255, 0, 0, 0.2)",
        4: "rgba(255, 0, 0, 0.1)",
      };

      const blockedLabels = [
        "Workflow::Blocked",
        "Discussion/ Not-Clear",
        "on call backlog/ dependent",
      ];

      const currentDate = moment(new Date(), "DD-MM-YYYY HH:mm:ss").valueOf();

      switch (column) {
        case "severity": {
          return {
            "background-color": severityColors[item[column]] || "rgba(255, 0, 0, 0)",
          };
        }
        case "labels": {
          if (blockedLabels.some((label) => item[column].includes(label))) {
            return { "background-color": "rgba(41, 0, 0, 0.2)" };
          }
          break;
        }
        case "assignee": {
          if (item[column] === "Unassigned") {
            return { "background-color": "rgba(41, 0, 0, 0.2)" };
          }
          break;
        }
        case "dueDate": {
          if (item[column]) {
            const dueDate = moment(
              item[column],
              "DD-MM-YYYY HH:mm:ss"
            ).valueOf();
            if (dueDate < currentDate) {
              return { "background-color": "rgba(41, 0, 0, 0.2)" };
            }
          }
          break;
        }
        default:
          break;
      }
      return {};
    },
    fetchHeaders() {
      this.columns.map((column) => {
        this.totalHeaders.push(column.name);
        if (column.visible) {
          this.selectedHeaders.push(column.name);
        }
      });
    },
  },
  mounted() {
    this.fetchHeaders();
  },
};
</script>
<style lang="scss" scoped>
.table_container {
  padding: 2px;
  margin: 0 20px 0 20px;
  background-color: var(--background-color-primary-light-dark);
}

.v-input__slot {
  height: 40px;
}

.v-data-table {
  border-radius: 8px;
  font-size: 40px;
  line-height: 18px;
  letter-spacing: 0.005em;
  color: #1e234c;
}

.hyperlink {
  color: rgb(248, 101, 66);
  font-weight: 500;
  transition: 0.3s;
}

.hyperlink:hover {
  text-decoration: underline;
  cursor: pointer;
}

.ew-custom-table td div{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  white-space: normal;
}

html {
  --default-column-width: 150px;
}

.ew-custom-table tbody tr td {
  text-align: left !important;
}


.v-input__icon i {
  position: absolute;
  color: #a0a3bd;
}

.v-input__prepend-inner {
  margin: 6px 8px 0px 8px !important;
}

.v-input__slot::before {
  border: none !important;
}


.v-select-header {
  text-align: left !important;
  max-width: 300px;
}

.title-column div{
  max-width: 350px;
  min-width: 350px;
}

.assignee-column div{
  min-width: 200px;
}

.labels-column{
  min-width: 200px;
}

.severity-column div{
  max-width: 20px !important;
  min-width: 10px;
}

.updatedDate-column div,
.dueDate-column div,
.createdDate-column div{
  min-width: 58px;
}

</style>
